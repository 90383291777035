<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="data-list-list-view" class="data-list-container">

    <vs-table ref="table" multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="submissions">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

    <!-- Contest selection -->
    <div class="text-xl h-12 mb-5 w-1/9"><label for="">إختر مسابقة</label></div>
    <v-select
            style="background-color:#fff;"
            class="text-xl w-1/3 h-12 mb-10"
            label="name"
            @input="fetchSubmissions"
            :options="contests"
            v-model="selectedContest"
            :tabindex="4"
            :filterable="true"
          ></v-select>
      <!-- <select v-model="selectedContest"  class="text-xl w-1/3 h-12 mb-10">
        <option 
        v-for="(contest,index) in contests" 
        :key="index" 
        @input="fetchSubmissions(contest.id, contest.mcq)"
        :value="contest.id">{{contest.name}}</option>
      </select> -->
    <vs-button class="w-1/4 h-12 mb-10 text-lg p-2 cr-contest-btn" @click="selectWinner(validSubmissionCount, isMCQ_winner)" color="success" type="filled">سحب على فائز</vs-button>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ submissions.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : submissions.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>


   
      </div>

      <template slot="thead">
        <vs-th class="text-lg" sort-key="title">الإسم</vs-th>
        <vs-th class="text-lg" sort-key="phone">رقم الهاتف</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="submissions" v-for="(submission, index) in data" :key="index">
            
              <!-- in all these place holders replace them with {{contest.ObjectHere}} -->


            <vs-td>
              <p class="product-name font-medium truncate text-lg"> {{submission.user ? submission.user.username :  'هذا المستخدم قام بمسح حسابه'}} </p>
            </vs-td>

            <vs-td>
              <p class="product-category text-lg">{{submission.user?submission.user.phone:'هذا المستخدم قام بمسح حسابه'}}</p>
            </vs-td>

          </vs-tr>
          
        </tbody>
      </template>
    </vs-table>

  </div>
</template>

<script>
import axios from "@/axios.js"
import moduleContest from "@/store/contests/moduleContest.js";
import vSelect from "vue-select";


export default {
  components: {
    axios,
    vSelect
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 4,
      isMounted: false,
      selectedContest: '',
      contestWinnersNo: 5, //number of users with correct answers in the contest
      contests: [],
      correctAnswerId: '',

      ValidSubmissions: [],

      validSubmissionCount: '',
      isMCQ_winner: '',
      submissions: [], //all contest submissions

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.submissions.length
    }
  },
  methods: {
    fetchContests(){
      this.$store.dispatch("contest/fetchContests",1).then((response) => {
          this.contests = response.data.contests;
      });
    },
    fetchSubmissions(){
      this.$vs.loading()
      // fetch the users who have submitted
      // also fetch the total number of submissions in the table
      let isMcq = this.selectedContest.mcq
      let contestID = this.selectedContest.id
      this.isMCQ_winner = isMcq; //to test select winner with it later
      this.isMCQ_winner = isMcq; //to test select winner with it later
      
      this.$store.dispatch("contest/getContest",contestID)
      .then((response) => {
        //submissions.user.username
        this.submissions = response.data.submissions;
        
        this.$vs.loading.close()
        
        if(isMcq){
          this.correctAnswerId = response.data.answers[3].id; //gets the id for the correct answer
          
          this.filterCorrectSubmissions(this.submissions);
          
          if(this.validSubmissionCount > 0)
          {
            for (let i = 0; i < this.validSubmissionCount; i++) {
              this.ValidSubmissions[i].userIndex =  i+1;  // added a gradually incremented user-index to apply randomization logic on
            }
            
          }

        }
        
        //this.submissions[0].contest_answer_id      gets the answer the user has submitteds


        
    });
      
    },
    filterCorrectSubmissions(sub){
      this.ValidSubmissions = [];
      sub.forEach(item => {
        if(item.contest_answer_id == this.correctAnswerId){
          this.ValidSubmissions.push(item);
        }
      });
      this.validSubmissionCount = this.ValidSubmissions.length;
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    },
    selectWinner(usersNumber, isMCQ){
        /* -Execution- */
        // what this block will do is get the last user ID in the list ex: 150 meaning that there is 150 users in the contest
        // then call math random between 0 and 150, the result is the winner.
        


        //get the user with "randomNumber" as  the id in it's property, then provide it's number and his name
        var randomNumber;
        if(isMCQ)
        {
          randomNumber = parseInt(Math.random() * usersNumber);
          if(this.validSubmissionCount > 0 ) {
              this.$vs.dialog({
                  type:'confirm',
                title: "تحديد فائز عشوائي",
                text: "الفائز هو:("+ this.ValidSubmissions[randomNumber].user ? this.ValidSubmissions[randomNumber].user.username:null + ")  ~ " + "رقم الهاتف: ("+this.ValidSubmissions[randomNumber].user ? this.ValidSubmissions[randomNumber].user.phone : null +")" ,
                color: "success",
                acceptText: "إرسال إشعار للفائز",
                cancelText: "إغلاق",
                accept: () =>{
                  this.sendNotification(this.ValidSubmissions[randomNumber].id);
                }
              });

          }
          else{

            this.$vs.dialog({
              title: "تحديد فائز عشوائي",
              text: "لا يوجد فائز بعد" ,
              color: "danger",
              acceptText: "إغلاق"
            });
          }
        }
        else
        {
          randomNumber = parseInt(Math.random() * (this.submissions.length));
          if(this.submissions.length>0)
          {
            this.$vs.dialog({
                type:'confirm',
                title: "تحديد فائز عشوائي",
                text: "الفائز هو:("+ this.submissions[randomNumber].user ? this.submissions[randomNumber].user.username : null + ")  ~ " + "رقم الهاتف: ("+this.submissions[randomNumber].user ? this.submissions[randomNumber].user.phone : null +") "+ "الإجابة: ("+this.submissions[randomNumber].text_answer +") " ,
                color: "success",
                acceptText: "إرسال إشعار للفائز",
                cancelText: "إغلاق",
                accept: () =>{
                  this.sendNotification(this.submissions[randomNumber].id);
                }
              });
          }
          else
          {
            this.$vs.dialog({
              title: "تحديد فائز عشوائي",
              text: "لا يوجد فائز بعد" ,
              color: "danger",
              acceptText: "إغلاق"
            });
          }
        }
    },
    sendNotification(id){
      //gets the user submission id to send notifications with the id that was grabbed from the winner 
      this.$store.dispatch("contest/notifyWinner", id)
      .then((response) => {
        this.$vs.dialog({
            title: "نجحت العملية",
            text: "تم إرسال ألإشعار للفائز بنجاح" ,
            color: "success",
            acceptText: "إغلاق"
          });
      })
      .catch((error)=>{
        this.$vs.dialog({
            type: "confirm",
            title: "فشلت العملية",
            text: "لم يتم إرسال ألإشعار للفائز الرجاء المحاولة من جديد" ,
            color: "danger",
            acceptText: "إعادة المحاولة",
            cancelText: "إلغاء",
            accept: ()=>{
              this.sendNotification(id); //retry notification
            }
            
          });
      });
    }
  },
  created() {
    
    if (!moduleContest.isRegistered) {
      this.$store.registerModule("contest", moduleContest);
      moduleContest.isRegistered = true;
    }
    this.fetchContests();

  },
  mounted() {
    this.isMounted = true;
  }
}
</script>








<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }
    .contest-picture{
      max-width:100px;
      max-height:100px
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .cr-contest-btn{
  font-family: "Cairo", sans-serif;
}

  }
}
</style>
