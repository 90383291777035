import axios from "@/axios.js"
import { is } from "core-js/core/object"

export default {
    fetchContests({commit},page){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/contests/index?page=${page}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getContest({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/contests/show/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    updateContest({commit}, payload){
        var id = payload.get('id');
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/contests/update/${id}`, payload)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    // fetchCategories({commit}){
    //     return new Promise((resolve,reject)=>{
    //         axios.get('api/dashboard/categories/index')
    //         .then((response)=>{
    //             commit('SET_CATEGORIES',response.data.categories);
    //             resolve(response)
    //         }).catch((error)=>{
    //             reject(error)
    //         })
    //     })
    // },

    createNewContest({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/contests/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    deleteContest({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/contests/delete/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    changeContestStatus({commit}, payload){
        var id = payload.get('id');
        var stat = payload.get('status');

        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/contests/change-status/${id}`, {"status": stat})
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    notifyWinner({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`/api/dashboard/contests/notify-winner/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    }
}


// api/dashboard/contests/index 