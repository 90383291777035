import state from './moduleContestState.js'
import mutations from './moduleContestMutations.js'
import actions from './moduleContestActions.js'
import getters from './moduleContestGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
